import { replace, push } from '@lagunovsky/redux-react-router';
import { omit } from 'lodash';

import { createLogicWithApi, createLogicWithRouteChecks } from '../../../../shared/logicCreators';
import { URL_CHANGE } from '../../../../shared/components/App/App.actions';
import { VERIFICATION_PUBSUB } from '../../common/PubSub/PubSub.actions';
import { table as tableConfig } from '../../../config/organizations';
import { organizations as apiConfig } from '../../../config/api';

import {
  FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATIONS_SUCCESS,
  CANCEL_FETCH_ORGANIZATIONS,
  FETCH_ORGANIZATION,
  CANCEL_FETCH_ORGANIZATION,
  SAVE_ORGANIZATION,
  SAVE_ORGANIZATION_SUCCESS,
  CANCEL_SAVE_ORGANIZATION,
  REMOVE_ORGANIZATION,
  REMOVE_ORGANIZATION_SUCCESS,
  CANCEL_REMOVE_ORGANIZATION,
  selectOrganizationTable,
  fetchOrganizationsSuccess,
  fetchOrganizationsError,
  fetchOrganization,
  fetchOrganizationSuccess,
  fetchOrganizationError,
  saveOrganizationSuccess,
  saveOrganizationError,
  removeOrganizationSuccess,
  removeOrganizationError,
  CANCEL_SAVE_ORGANIZATION_FILE,
  SAVE_ORGANIZATION_FILE,
  saveOrganizationFileSuccess,
  saveOrganizationFileError,
  saveOrganizationFile,
  removeOrganizationFileSuccess,
  removeOrganizationFileError,
  CANCEL_REMOVE_ORGANIZATION_FILE,
  REMOVE_ORGANIZATION_FILE,
} from './Organizations.actions';

const checkOrganizationRouteLogic = createLogicWithRouteChecks({
  type: URL_CHANGE,
  process: ({ checkUnselectedIdParam, checkInitSelect }, dispatch, done) => {
    const selectId = checkInitSelect('organizations');
    
    if (selectId) {
      dispatch(replace(`${apiConfig.path}/${selectId}${window.location.search}`));

    } else {
      const newIdParam = checkUnselectedIdParam('organizations');

      if (newIdParam !== false) {
        dispatch(selectOrganizationTable(newIdParam));
        dispatch(fetchOrganization(newIdParam));
      }
    }

    done();
  }
});

const fetchOrganizationsLogic = createLogicWithApi({
  type: FETCH_ORGANIZATIONS,
  cancelType: CANCEL_FETCH_ORGANIZATIONS,
  latest: true,
  process: ({ Api, getState, action }, dispatch, done) => {
    const state = getState();
    const search = typeof action.search !== 'undefined' ? action.search : state.table.search;

    return Api({
      method: 'get',
      path: apiConfig.path,
      handle: r => {
        const sort = action.sort ? action.sort : state.table.items[tableConfig.name].sort;
        const desc = typeof action.desc !== 'undefined' ? action.desc : state.table.items[tableConfig.name].desc;
        const limit =  typeof action.limit !== 'undefined' ? action.limit : state.table.items[tableConfig.name].limit;
        const offset =  typeof action.offset !== 'undefined' ? action.offset : state.table.items[tableConfig.name].offset;

        if (offset) {
          r.query({'offset': offset});
        }

        r
          .query({'limit': limit})
          .query({'query': search})
          .query({'order[field]': sort})
          .query({'order[dir]': desc ? 'desc' : 'asc' });

        return r;
      }
    })
    .then(resp => {
      dispatch(fetchOrganizationsSuccess(resp));
      done();
    })
    .catch(err => {
      dispatch(fetchOrganizationsError(err));
      done();
    });
  }
});

const checkFetchedOrganizationSelectionLogic = createLogicWithRouteChecks({
  type: FETCH_ORGANIZATIONS_SUCCESS,
  process: ({ checkInitSelect }, dispatch, done) => {
    const selectId = checkInitSelect('organizations');

    if (selectId) {
      dispatch(replace(`${apiConfig.path}/${selectId}${window.location.search}`));
    }

    done();
  }
});

const fetchOrganizationLogic = createLogicWithApi({
  type: FETCH_ORGANIZATION, //[ FETCH_ORGANIZATION, VERIFICATION_PUBSUB ],
  cancelType: CANCEL_FETCH_ORGANIZATION,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'get',
      path: `${apiConfig.path}/${action.id}`
    })
      .then(resp => {
        dispatch(fetchOrganizationSuccess(resp));
        done();
      })
      .catch(err => {
        dispatch(fetchOrganizationError(err));
        done();
      });
  }
});

const saveOrganizationLogic = createLogicWithApi({
  type: SAVE_ORGANIZATION,
  cancelType: CANCEL_SAVE_ORGANIZATION,
  process: ({ action, Api }, dispatch, done) => {
    const method = action.payload.id ? 'PATCH' : 'post';
    const path = `${apiConfig.path}${action.payload.id ? `/${action.payload.id}` : ''}`;
    const letterFile = action?.payload?.letterFile;    

    if (letterFile) {
      delete action.payload.letterFile;
    }

    return Api({
      method: method,
      path: path,
      handle: r => r.send(action.payload.id 
        ? {
          attributes: omit(action.payload, ['file']),
          type: 'organization',
          id: action.payload.id ? action.payload.id : null,
        } 
        : {
          attributes: omit(action.payload, ['file']),
        }
      )
    })
      .then(resp => {
        if (letterFile && letterFile.length) {
          dispatch(saveOrganizationFile(resp.data.id, letterFile[0]));
        }

        return resp;
      })
      .then(resp => {
        dispatch(saveOrganizationSuccess(resp, action.nextStep, action.payload.id));
        done();
      })
      .catch(err => {
        dispatch(saveOrganizationError(err));
        done();
      });
  }
});

const saveOrganizationSuccessLogic = createLogicWithRouteChecks({
  type: SAVE_ORGANIZATION_SUCCESS,
  process: ({ state, action }, dispatch, done) => {
    if (action.nextStep !== false) {
      if (action.nextStep) {
        dispatch(push(`${apiConfig.path}/${action.payload.data.id}${state.locale.actions.create.path}/${action.nextStep}${window.location.search}`));

      } else {
        dispatch(push(`${apiConfig.path}/${action.payload.data.id}${window.location.search}`));
      }
    }

    done();
  }
});

const removeOrganizationLogic = createLogicWithApi({
  type: REMOVE_ORGANIZATION,
  cancelType: CANCEL_REMOVE_ORGANIZATION,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'DELETE',
      path: `${apiConfig.path}/${action.payload.id}`,
    })
      .then(() => {
        dispatch(removeOrganizationSuccess({ id: action.payload.id }));
        done();
      })
      .catch(err => {
        dispatch(removeOrganizationError(err));
        done();
      })
  }
});

const checkRemoveOrganizationLogic = createLogicWithRouteChecks({
  type: REMOVE_ORGANIZATION_SUCCESS,
  process: (_, dispatch, done) => {
    dispatch(push(`${apiConfig.path}${window.location.search}`));
    done();
  }
});

const saveOrganizationFileLogic = createLogicWithApi({
  type: SAVE_ORGANIZATION_FILE,
  cancelType: CANCEL_SAVE_ORGANIZATION_FILE,
  process: ({ action, Api }, dispatch, done) => {
    const path = `${apiConfig.path}/${action.organizationId}${apiConfig.filePath}`;

    if (!action.payload?.file) {
      return Promise.resolve().then(() => done());
    }
    
    return Api({
      method: 'POST',
      path: path,
      handle: r => {
        r
          .field('data[attributes][file][state]', 'published')
          .attach('data[attributes][file][file]', action.payload.file);

        return r;
      }
    })
    .then(resp => {
      dispatch(saveOrganizationFileSuccess(action.organizationId, resp));
      done();
    })
    .catch(err => {
      dispatch(saveOrganizationFileError(err));
      done();
    });
  }
});

const removeOrganizationFileLogic = createLogicWithApi({
  type: REMOVE_ORGANIZATION_FILE,
  cancelType: CANCEL_REMOVE_ORGANIZATION_FILE,
  latest: true,
  process: ({ action, Api }, dispatch, done) => {
    return Api({
      method: 'DELETE',
      path: `${apiConfig.path}/${action.payload.id}${apiConfig.filePath}/${action.payload.file.id}`,
    })
      .then(() => {
        if (action.turnOffLoader) {
          dispatch(removeOrganizationFileSuccess(action.payload));
        }

        done();
      })
      .catch(err => {
        dispatch(removeOrganizationFileError(err));
        done();
      })
  }
});

const logics = [
  checkFetchedOrganizationSelectionLogic,
  checkOrganizationRouteLogic,
  fetchOrganizationsLogic,
  fetchOrganizationLogic,
  saveOrganizationLogic,
  removeOrganizationLogic,
  saveOrganizationFileLogic,
  removeOrganizationFileLogic,
  saveOrganizationSuccessLogic,
  checkRemoveOrganizationLogic
];

export default logics;