import {
  VIEW_LOCATION,
  EDIT_LOCATION,
  VIEW_USER,
  EDIT_SETTINGS,
  VIEW_LOAN,
  VIEW_EQUIPMENT,
  EDIT_CATEGORY,
  VIEW_RESERVATION,
  VIEW_ADMIN_USER,
  VIEW_ORGANIZATION
} from './access_rights';

export const login = {
  key: 'login',
  inMenu: false,
  logoutRequired: true,
  reducer: null // initialReducers
}

export const logout = {
  key: 'logout',
  inMenu: false,
  loginRequired: true,
  reducer: null
};

export const dashboard = {
  key: 'dashboard',
  inMenu: true,
  index: true,
  loginRequired: true,
  reducer: null
};

export const loans = {
  key: 'loans',
  inMenu: true,
  inDashboard: true,
  loginRequired: true,
  accessRights: {
    rules: {
      [VIEW_LOAN]: true
    }
  },
  reducer: () => import('../../shared/components/Loans/Loans.reducers')
};

export const equipment = {
  key: 'equipment',
  inMenu: true,
  inDashboard: true,
  loginRequired: true,
  accessRights: {
    rules: {
      [VIEW_EQUIPMENT]: true
    }
  },
  reducer: () => import('../../shared/components/Equipment/Equipment.reducers')
};

export const reservations = {
  key: 'reservations',
  inMenu: true,
  inDashboard: true,
  loginRequired: true,
  accessRights: {
    rules: {
      [VIEW_RESERVATION]: true
    }
  },
  reducer: () => import('../../shared/components/Reservations/Reservations.reducers')
};

export const users = {
  key: 'users',
  inMenu: true,
  inDashboard: true,
  loginRequired: true,
  accessRights: {
    rules: {
      [VIEW_USER]: true
    }
  },
  reducer: () => import('../components/pages/Users/Users.reducers')
};

export const categories = {
  key: 'categories',
  inMenu: true,
  inDashboard: true,
  loginRequired: true,
  accessRights: {
    rules: {
      [EDIT_CATEGORY]: true
    }
  },
  reducer: null // initialReducers
};

// export const organizations = {
//   key: 'organizations',
//   inMenu: true,
//   inDashboard: true,
//   loginRequired: true,
//   accessRights: {
//     rules: {
//       [VIEW_ORGANIZATION]: true
//     }
//   },
//   reducer: () => import('../components/pages/Organizations/Organizations.reducers')
// };

export const locations = {
  key: 'locations',
  inMenu: true,
  inDashboard: true,
  loginRequired: true,
  accessRights: {
    rules: {
      [EDIT_LOCATION]: true
    }
  },
  reducer: null // initialReducers
};

export const settings = {
  key: 'settings',
  inMenu: true,
  inDashboard: true,
  loginRequired: true,
  accessRights: {
    rules: {
      [VIEW_LOCATION]: true,
      [EDIT_LOCATION]: false,
      [EDIT_SETTINGS]: true
    }
  },
  reducer: () => import('../components/pages/Settings/Settings.reducers')
};

export const staff = {
  key: 'staff',
  inMenu: true,
  inDashboard: true,
  loginRequired: true,
  accessRights: {
    rules: {
      [VIEW_ADMIN_USER]: true
    }
  },
  reducer: () => import('../components/pages/Staff/Staff.reducers')
}

export const routesWithLocation = [
  loans,
  equipment,
  reservations,
  staff
];

const routes = [
  login,
  logout,
  dashboard,
  loans,
  equipment,
  users,
  // organizations,
  reservations,
  staff,
  settings,
  categories,
  locations,
];

export default routes;