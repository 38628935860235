import { media } from '../../shared/styles/media';

const t = {};

// GRID

t.space       = [ 0, 8, 16, 32, 64 ];

t.intBreakpoints  = [ 768, 1024, 1200 ];
t.breakpoints     = t.intBreakpoints.map(px => `${px}px`);
t.gutter          = t.space[1];
t.media           = media(t.intBreakpoints);

// FONTS

t.fonts = {
  regular: 'GT-Walsheim-Pro-Regular',
  medium: 'GT-Walsheim-Pro-Medium',
  light: 'GT-Walsheim-Pro-Light'
};

// COLORS

t.colorPurple       = '#a267d5';
t.colorPurpleHover  = '#b177e2';
t.colorPurpleActive = '#9259c3';
t.colorPurpleDarker = '#9a319f';
t.colorPurpleDark = '#87468a';
t.colorPurpleTransparent = 'rgba(162, 103, 213, 0.12)';
t.colorLightTransparentPurple = 'rgba(154, 49, 159, 0.16)';
t.colorGreen        = '#33c751';
t.colorGreenLight   = '#3fd26e';
t.colorGreenDark    = '#00a984';
t.colorTurquoise  = '#52b8a0';
t.colorOrange       = '#ff8c00';
t.colorOrangeDark   = '#c76e01';
t.colorBlue         = '#6c75ff';
t.colorRed          = '#ff3e71';
t.colorRedLight     = '#eb6461';

t.colorGrayDarkest  = '#252b3a';
t.colorGrayDarker   = '#363c4c';
t.colorGrayDark     = '#798296';
t.colorGray         = '#a3abbd';
t.colorGrayLight    = '#dfe3ea';
t.colorGrayLighter  = '#f4f6f9';
t.colorGrayLightest = '#f8fafd';
t.colorWhite        = '#ffffff';

// SIZES

t.menuSize          = 240;
t.detailSectionSize = 320;

export default t;
