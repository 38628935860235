import Cookies from 'js-cookie';
import { createLogic, createLogicWithApi } from '../../../../shared/logicCreators';
import { cookies as cookieConfig } from '../../../config/login';
import { resetTable } from '../../common/Table/Table.actions.js';
import { login as apiConfig } from '../../../config/api';
import { push, replace } from '@lagunovsky/redux-react-router';
import { authClient, createAuthParams } from '../../../config/auth';

import {
  loginError,

  FETCH_LOGIN_INFO,
  fetchLoginInfo,
  setLoginInfo,

  LOGOUT,
  LOGOUT_SUCCESS,
  logoutError,
  logout,
  SET_LOGIN_TOKEN,
  CHECK_LOGIN_TOKEN,
  setLoginToken,
} from '../../../../shared/components/Login/Login.actions';
import moment from 'moment';

const checkLoginLogic = createLogic({
  type: CHECK_LOGIN_TOKEN,
  process: async (props, dispatch, done) => {
    const query = window.location.search;
    const auth0Config = createAuthParams();

    if (query.includes("code=") && query.includes("state=")) {
      const auth = await authClient.handleRedirectCallback();
      dispatch(replace(auth?.appState?.redirect));
    }
    
    try {
      const token = await authClient.getTokenSilently({...auth0Config, 
        timeoutInSeconds: 58 // response time should be less then 60 seconds
      });
      if (token) {
        dispatch(setLoginToken({token}));
      }
      done();
    } catch (err) {
      authClient.loginWithRedirect(auth0Config);
      done();
    }
  }
});

const setLoginTokenLogic = createLogic({
  type: [SET_LOGIN_TOKEN],
  warnTimeout: 0,
  process: ({ getState }, dispatch) => {
    const { login: { token } } = getState();
    if (token) {
      Cookies.set(
        cookieConfig.token.name,
        token
      );
      dispatch(fetchLoginInfo());
    }
  }
});

const fetchTokenLogic = createLogicWithApi({
  type: FETCH_LOGIN_INFO,
  process: async ({ Api }, dispatch, done) => {
    const tokenClaims = await authClient.getIdTokenClaims();

    return Api({
      method: 'get',
      withCredentials: true,
      path: apiConfig.check
    })
    .then(resp => {
      dispatch(setLoginInfo({
        ...resp,
        data: {
          ...resp.data,
          ...tokenClaims,
        }
      }, moment()));
      done();
    })
    .catch(err => {
      dispatch(loginError(err));
      done();
    });
  }
});

const logoutLogic = createLogic({
  type: LOGOUT,
  process: (props, dispatch, done) => {
    dispatch(push(window.LOGOUT_URL));
    done();
  }
});

const logoutSuccessLogic = createLogicWithApi({
  type: LOGOUT_SUCCESS,
  process: ({ Api }, dispatch, done) => {
    Cookies.remove(cookieConfig.token.name);
    Cookies.remove(cookieConfig.refresh_token.name);
    dispatch(resetTable());

    return Api({
      method: 'post',
      path: apiConfig.logout,
      withCredentials: true,
    })
      .then(() => {
        authClient.logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
        done();
      })
      .catch(err => {
        dispatch(logoutError(err));
        authClient.logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
        done();
      });
  }
});

const logics = [
  checkLoginLogic,
  setLoginTokenLogic,
  fetchTokenLogic,
  logoutLogic,
  logoutSuccessLogic,
];

export default logics;